.Schedule {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: var(--padding-default);
}

.Schedule-heading {
  font-weight: var(--font-weight-bold);
  opacity: 0.5;
}

.Schedule-link {
  text-decoration: none;
  color: inherit;
}