.LiveNowCard-teams {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.LiveNowCard-team-logo {
  width: 54px;
  height: auto;
}

.LiveNowCard-title {
  flex: 1;
  text-align: center;
}

.LiveNowCard-title-team {
  font-weight: var(--font-weight-bold);
}

.LiveNowCard-actions {
  display: flex;
  justify-content: center;
  gap: var(--padding-light);
}