@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;700&display=swap');

:root {
  --colour-primary: #DD0201;
  --colour-light: #F0F0F0;
  --colour-dark: #2A2A2A;
  --colour-grey: #DDDDDD;
  --colour-white: #FFFFFF;

  --padding-minimum: 0.3125rem;
  --padding-light: 0.625rem;
  --padding-default: 1rem;
  --padding-heavy: 2rem;

  --radius-small: 0.3125rem;
  --radius-default: 0.625rem;

  --shadow-default: 0px 2px 3px rgba(0, 0, 0, 0.2);

  --font-size-large: 1.125rem;
  --font-size-small: 0.875rem;

  --font-weight-light: 200;
  --font-weight-normal: 300;
  --font-weight-bold: 600;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--colour-light);
  font-family: 'Inter', sans-serif;
  font-weight: var(--font-weight-normal);
  color: var(--colour-dark);
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid var(--colour-grey);
}


.button {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: var(--padding-light);
  padding: var(--padding-light);
  background-color: var(--colour-light);
  border: none;
  border-radius: var(--radius-small);
  font-family: inherit;
  font-size: 1rem;
  text-decoration: none;
  color: var(--colour-dark);
  cursor: pointer;
}
.button:hover {
  text-decoration: underline;
}
.button--white {
  background-color: var(--colour-white);
  box-shadow: var(--shadow-default);
}

.button-icon,
.button img,
.button svg {
  width: auto;
  height: 1em;
}


.textfield {
  display: block;
  padding: var(--padding-light);
  border: 1px solid var(--colour-grey);
  border-radius: var(--radius-small);
  font-family: inherit;
  font-size: 1rem;
  color: var(--colour-dark);
}