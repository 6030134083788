.Details {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--padding-heavy);
}

.Details-datetimevenue {
  text-align: center;
}

.Details-form form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--padding-light);
  text-align: center;
}
.Details-form form,
.Details-form .StripeElement {
  width: 100%;
}
.Details-form .textfield {
  align-self: stretch;
}
.Details-form .error {
  font-weight: var(--font-weight-bold);
  color: red;
}

.Details-player {
  text-align: center;
}

.Details-warning {
  font-size: var(--font-size-small);
  opacity: 0.5;
}