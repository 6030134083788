.Card {
  background-color: var(--colour-white);
  border-radius: var(--radius-default);
  box-shadow: var(--shadow-default);
}

.Card-inner {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--padding-light);
  padding: var(--padding-light);
}

.Card-header {
  padding: var(--padding-minimum);
  border-top-left-radius: var(--radius-default);
  border-top-right-radius: var(--radius-default);
  background-color: var(--colour-dark);
  text-align: center;
  color: var(--colour-white);
}

.Card-title {
  font-weight: var(--font-weight-bold);
}