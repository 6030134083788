.Modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Modal-inner {
  max-width: 25rem; /* 400px */
  margin: 0 auto;
  padding: var(--padding-default);
  padding-top: calc(2rem + 10vw);
}

.Modal-dialog {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--padding-light);
  padding: var(--padding-light);
  background-color: var(--colour-white);
  border-radius: var(--radius-default);
  box-shadow: var(--shadow-default);
}