.StartingSoonCard-inner {
  flex-direction: row;
  gap: var(--padding-default);
}

.StartingSoonCard-teams {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.StartingSoonCard-team-logo {
  width: 36px;
  height: auto;
}

.StartingSoonCard-title {
  flex: 1;
  text-align: center;
}

.StartingSoonCard-title-team {
  font-weight: var(--font-weight-bold);
}

.StartingSoonCard-datetime {
  width: 110px;
  text-align: right;
}