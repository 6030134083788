.App {
  max-width: 30rem; /* 480px */
  margin: 0 auto;
  padding: var(--padding-default);
  padding-top: calc(2rem + 5vw);
}

.App-inner {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.App-header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--padding-default);
  margin-bottom: var(--padding-heavy);
}

.App-logos {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: var(--padding-default);
}

.App-logo-sportsbug {
  width: 39%;
  height: auto;
  /* TODO: font styles for alt text */
}

.App-logo-varsity {
  width: 31%;
  height: auto;
}

.App-tagline {
  opacity: 0.5;
}