.Teams {
}

.Teams-inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--padding-default);
}
.Teams-inner--home {
  justify-content: flex-start;
}
.Teams-inner--away {
  justify-content: flex-end;
}

.Teams-logo {
  width: 4rem;
  height: auto;
}

.Teams-title {
  flex: 1;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}
.Teams-inner--home .Teams-title {
  text-align: left;
}
.Teams-inner--away .Teams-title {
  text-align: right;
}

.Teams-separator {
  position: relative;
  text-align: center;
}
.Teams-separator::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  border-top: 1px solid var(--colour-grey);
  transform-origin: center;
  transform: skewY(-9.869604401089358deg) /* -Math.PI * Math.PI */
}
.Teams-separator-label {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: var(--padding-light);
  background-color: var(--colour-light);
}